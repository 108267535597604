import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_file_pond = _resolveComponent("file-pond")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
    _createVNode(_component_file_pond, {
      name: "test",
      ref: "pond",
      "label-idle": "Drop files here...",
      "allow-multiple": true,
      "accepted-file-types": "image/jpeg, image/png",
      server: "/api",
      files: _ctx.myFiles,
      onInit: _ctx.handleFilePondInit
    }, null, 8, ["files", "onInit"])
  ], 64))
}

import { defineComponent } from 'vue'
import Datagrid1 from '@/components/Datagrid1.vue'
import UploadFile from '@/components/UploadFile.vue'
import UploadAndEdit from '@/components/UploadAndEdit.vue'

export default defineComponent({
  name: 'Home',
  components: {
    Datagrid1,
    UploadFile,
    UploadAndEdit
  }
})

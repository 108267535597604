
import VGrid from '@revolist/vue3-datagrid'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Datagrid',
  data () {
    return {
      columns: [{
        prop: 'albumId',
        name: 'AlbumID',
        sortable: true,
        order: 'asc',
        size: 100
      },
      {
        prop: 'id',
        name: 'ID',
        sortable: true,
        order: 'asc',
        size: 100
      },
      {
        prop: 'title',
        name: 'Title',
        sortable: true,
        order: 'asc',
        size: 300
      },
      {
        prop: 'url',
        name: 'URL',
        size: 300
      },
      {
        prop: 'thumbnailUrl',
        name: 'Thumbnail URL',
        size: 300
      },
      {
        prop: 'title',
        name: 'Title',
        sortable: true,
        order: 'asc',
        size: 300
      },
      {
        prop: 'url',
        name: 'URL',
        size: 300
      },
      {
        prop: 'thumbnailUrl',
        name: 'Thumbnail URL',
        size: 300
      }
      ],
      rows: [],
      filterNames: {
        none: 'None',
        empty: 'Not set',
        notEmpty: 'Set',

        eq: 'Equal',
        notEq: 'Not equal',
        begins: 'Begins with',
        contains: 'Contains',
        notContains: 'Does not contain',

        eqN: '=',
        neqN: '!=',
        gt: '>',
        gte: '>=',
        lt: '<',
        lte: '<='
      }
    }
  },
  components: {
    VGrid
  },
  mounted () {
    this.axios.get('https://jsonplaceholder.typicode.com/photos').then((response) => {
      this.rows = response.data
    })
  },
  methods: {
    exportDg () {
      console.log('ok')
      const grid = document.querySelector('revo-grid')
      console.log(grid)
      /* grid.getPlugins().then(plugins => {
        plugins.forEach(p => {
          if (p.exportFile) {
            const exportPlugin = p
            exportPlugin.exportFile({ filename: 'new file' })
          }
        })
      }) */
    }
  }
})

